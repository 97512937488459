import React from 'react'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <div className="footer-container">
      <div className="footer-container1">
        <div className="footer-container2">
          <h1 className="footer-text textXL">Внимание</h1>
          <span className="footer-text1">
            <span className="footer-text2">
              Сервис используется внутри конкретной организации
            </span>
            <br className="footer-text3"></br>
            <span className="footer-text4"> на момент разработки.</span>
            <br></br>
          </span>
        </div>
        <div className="footer-container3">
          <a
            href="https://qi.genresnoiz.com/admin/"
            className="footer-link button"
          >
            {props.button}
          </a>
        </div>
      </div>
      <div className="footer-container4"></div>
      <span className="footer-text6 textSM">Copyright © 2022. Genres Noiz</span>
    </div>
  )
}

Footer.defaultProps = {
  button: 'Открыть',
}

Footer.propTypes = {
  button: PropTypes.string,
}

export default Footer
